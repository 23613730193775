import React, { useState } from 'react';
import { useHistory, NavLink, useLocation } from 'react-router-dom';
import jwt from 'jwt-decode';
import { Nav, NavDropdown } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { useAppDispatch } from '../app/hooks';
import { logOutUser } from '../features/Auth/AuthSlice';
import { Constants } from '../global/Constants';
import { scopeProperty } from '../global/scopePayload';

import { ReactComponent as Logo } from '../assets/images/maia-text.svg';

import './styles/Header.scss';
import { MiddlewareArray } from '@reduxjs/toolkit';
import Banner from './banner/Banner';
import { Controller } from '../appcontroller/appcontrol';

const Header = () => {
  const tabIndex = 0;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [headerClick, setHeaderClick] = useState(false);
  const onNavClick = (view: string) => {
    history.push(`${view}`);
  };

  const jwtDecode: any = jwt(localStorage.getItem('token') || '');
  const shortenRole: Array<string> = jwtDecode.scopePermissions || [];
  const username: any = `${jwtDecode.name || ''} ${jwtDecode.lastName || ''}`;
  const local_name = localStorage.getItem('username') || '';

  const userRoles = Constants.USER_ROLES;
  const logout = async () => {
    const token = localStorage.getItem('token');
    dispatch(logOutUser());
    localStorage.clear();
    window.location.href = `${process.env.REACT_APP_MAIA_PORTAL_URL}/logout`;
    // await fetch(`${process.env.REACT_APP_MAIA_PORTAL_URL}`, {
    //   headers: new Headers({
    //     Authorization: `Bearer ${token}`,
    //   }),
    // });
    // dispatch(logOutUser());
    // history.push(`/login`);
  };
  const location = useLocation();

  const getDashboardLink = () => {
    if (
      shortenRole.includes(scopeProperty.ALL_ACCESS) &&
      Object.keys(jwtDecode).includes('maiaScope')
    ) {
      return `${process.env.REACT_APP_MAIA_PORTAL_URL}/rbac/trust/overview/`;
    }
    return `${process.env.REACT_APP_MAIA_PORTAL_URL}/dashboard/`;
  };

  return (
    <>
      {Controller.isBannerVisible && (
        <header className='banner-blk'>
          <Banner bannerHeight={location.pathname === '/' ? 'big' : 'small'} />
        </header>
      )}
      <header className='header-nav-blk'>
        <div className='header-block'>
          <div className='profile-div'>
            <div className='header-img'>
              <Logo />
            </div>
            <div
              className='menu-blk'
              style={{
                width: `calc(100% - 415px)`,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <a className='pri-nav-link' href={getDashboardLink()}>
                Dashboard
              </a>
            </div>
            <div
              className='menu-block'
              style={{ width: 250, justifyContent: 'flex-end' }}
            >
              <div className='profile-dropdown'>
                <NavDropdown
                  id='my-account-button'
                  title={
                    username
                      ? username.charAt(0).toUpperCase() + username.slice(1)
                      : local_name.charAt(0).toUpperCase() + local_name.slice(1)
                  }
                  className={isMobile ? 'my-account-mobile' : 'my-account-web'}
                  menuVariant='dark'
                >
                  {shortenRole[0] !== 'SA' ? (
                    <NavDropdown.Item href='/profile'>Account</NavDropdown.Item>
                  ) : (
                    ''
                  )}
                  <NavDropdown.Item onClick={() => logout()}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            </div>
          </div>
          <div className='header-info-section'>
            <h1 tabIndex={tabIndex}>RESOURCES</h1>
            <p tabIndex={tabIndex}>
              Update your resource information, manage your users and resource
              library
            </p>
          </div>
        </div>
      </header>
      <header>
        {userRoles && shortenRole ? (
          <div className='primaryNav '>
            <ul className='d-md-flex justify-content-center overflow-auto'>
              {shortenRole.includes('L1') ? (
                <li role='presentation'>
                  <Nav.Item className=''>
                    <NavLink
                      to='/trust'
                      activeClassName='activeMenu'
                      className={
                        isMobile ? 'nav-items nav-items-m-font' : 'nav-items'
                      }
                    >
                      Trust management
                    </NavLink>
                    {/* <div className="header-mark"> </div> */}
                  </Nav.Item>
                </li>
              ) : (
                ''
              )}
              {shortenRole.includes(scopeProperty.ALL_ACCESS) &&
                !Object.keys(jwtDecode).includes('maiaScope') && (
                  <>
                    <li role='presentation'>
                      <Nav.Item className=''>
                        <NavLink
                          to='/systemAdmin'
                          activeClassName='activeMenu'
                          className={
                            isMobile
                              ? 'nav-items nav-items-m-font'
                              : 'nav-items'
                          }
                        >
                          Super Admin management
                        </NavLink>
                        {/* <div className="header-mark"> </div> */}
                      </Nav.Item>
                    </li>
                    <li role='presentation'>
                      <Nav.Item className=''>
                        <NavLink
                          to='/roles'
                          activeClassName='activeMenu'
                          className={
                            isMobile
                              ? 'nav-items nav-items-m-font'
                              : 'nav-items'
                          }
                        >
                          Roles
                        </NavLink>
                        {/* <div className="header-mark"> </div> */}
                      </Nav.Item>
                    </li>
                  </>
                )}
              {shortenRole.includes(scopeProperty.USER_CREATE) ? (
                <li role='presentation'>
                  <Nav.Item className=''>
                    <NavLink
                      to='/user-management'
                      activeClassName='activeMenu'
                      className={
                        isMobile ? 'nav-items nav-items-m-font' : 'nav-items'
                      }
                    >
                      User management
                    </NavLink>
                    {/* <div className="header-mark"> </div> */}
                  </Nav.Item>
                </li>
              ) : (
                ''
              )}
              {shortenRole.includes(scopeProperty.RESOURCE_VIEW) ? (
                <li role='presentation'>
                  <Nav.Item className=''>
                    <NavLink
                      exact
                      to='/resource'
                      activeClassName='activeMenu'
                      className={
                        isMobile ? 'nav-items nav-items-m-font' : 'nav-items'
                      }
                    >
                      Current resource list
                    </NavLink>
                    {/* <div className="header-mark"> </div> */}
                  </Nav.Item>
                </li>
              ) : (
                ''
              )}
              {shortenRole.includes(scopeProperty.RESOURCE_CREATE) ? (
                <li role='presentation'>
                  <Nav.Item className=''>
                    <NavLink
                      exact
                      to='/resource/create'
                      activeClassName='activeMenu'
                      className={
                        isMobile ? 'nav-items nav-items-m-font' : 'nav-items'
                      }
                    >
                      Create new resource
                    </NavLink>
                    {/* <div className="header-mark"> </div> */}
                  </Nav.Item>
                </li>
              ) : (
                ''
              )}

              <li role='presentation'>
                <Nav.Item className=''>
                  <NavLink
                    to='/notification-centre'
                    activeClassName='activeMenu'
                    className={
                      isMobile ? 'nav-items nav-items-m-font' : 'nav-items'
                    }
                  >
                    My notifications
                  </NavLink>
                  {/* <div className="header-mark"> </div> */}
                </Nav.Item>
              </li>
              {(shortenRole.includes(scopeProperty.RESOURCE_PUBLISHED_VIEW) ||
                (shortenRole.includes(scopeProperty.ALL_ACCESS) &&
                  Object.keys(jwtDecode).includes('maiaScope'))) && (
                <li role='presentation'>
                  <Nav.Item className=''>
                    <NavLink
                      to='/resource-library'
                      activeClassName='activeMenu'
                      className={
                        isMobile ? 'nav-items nav-items-m-font' : 'nav-items'
                      }
                    >
                      Resource library
                    </NavLink>
                    {/* <div className="header-mark"> </div> */}
                  </Nav.Item>
                </li>
              )}
            </ul>
          </div>
        ) : (
          ''
        )}
      </header>
    </>
  );
};

export default Header;
