import React, { Suspense, useEffect,useRef } from "react";
import { Switch, Route, Redirect, Router, useLocation , useHistory } from "react-router-dom";
import jwt from "jwt-decode";
import { useAppDispatch } from "../app/hooks";
import { PrivateRoute } from "./PrivateRoute";
import { Profile } from "../pages/Account/Profile";
import { ResourcesApi } from "../pages/ResourcesApi/ResourcesApi";
import {clearState} from "../features/Auth/AuthSlice";

import Loader from "../components/loading/Loading";
import Level1User from "../pages/Level1User/Level1User";
import Roles from "../pages/Roles/Roles";
import { findOrInsertUser } from "../features/Auth/AuthSlice";
import { scopeProperty } from "../global/scopePayload";

const Login = React.lazy(() => import("../pages/Login/Login"));
const ForgotPassword = React.lazy(
  () => import("../pages/ForgotPassword/ForgotPassword")
);
const ResetPassword = React.lazy(
  () => import("../pages/ForgotPassword/ResetPassword")
);
const Resource = React.lazy(() => import("../pages/Resource/Resource"));
const UserManagement = React.lazy(
  () => import("../pages/UserManagement/UserManagement")
);
const Trust = React.lazy(() => import("../pages/Trust/TrustList"));
const Notification = React.lazy(
  () => import("../pages/Notification/Notification")
);
const NotificationView = React.lazy(
  () => import("../pages/Notification/NotificationView")
);
const NoAccess = React.lazy(() => import("../pages/NoAccess/NoAccess"));
const ResourceLibrary = React.lazy(
  () => import("../pages/ResourceLibrary/ResourceLibrary")
);
const ResourceType = React.lazy(
  () => import("../pages/ResourceTopic/ResourceTopic")
);
const ResourceTopic = React.lazy(
  () => import("../pages/ResourceTopic/ResourceTopic")
);
const ResourceTopicView = React.lazy(
  () => import("../pages/ResourceTopic/ResourceTopicView")
);
const ResourceTypeView = React.lazy(
  () => import("../pages/ResourceType/ResourceTypeView")
);

console.log(process.env.REACT_APP_LOGIN_TYPE)

function CheckValidLogin() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const token = new URLSearchParams(search).get('token');
  let checkUser;
  const countRef = useRef(0);
 
  useEffect(() => {
    async function checkLogin() {
      countRef.current++;
       checkUser = await loginCheck(token);
      const jwtDecode: any = jwt(localStorage.getItem("token") || "");
      const shortenRoles = jwtDecode?.scopePermissions || [];
      let indexUrl = "/resource";
      if (shortenRoles?.includes("all") && Object.keys(jwtDecode).includes('maiaScope')) {
        indexUrl = "/notification-centre";
      }else if (shortenRoles?.includes("all")) {
        indexUrl = "/systemAdmin";
      } else if (shortenRoles?.includes("L1")) {
        indexUrl = "/trust";
      }
      else if (shortenRoles?.includes("user:create")) {
        indexUrl = "/user-management";
      }
      else if (shortenRoles?.includes("resource:create") || shortenRoles?.includes("resource:approve") || shortenRoles?.includes("resource:publish")) {
        indexUrl = "/resource"; 
      }
      if (localStorage.getItem("token")) {
        window.location.href = indexUrl;
      }
      dispatch(clearState());
      history.push(indexUrl);
    }
    checkLogin()

  });
  const loginCheck = (requestQuery?: any) => {
    console.log("🚀 ~ file: index.tsx:92 ~ loginCheck ~ countRef:", countRef)
    if (countRef.current == 1)
    {
      localStorage.clear();
      dispatch(findOrInsertUser(requestQuery))
      .unwrap()
      .then((data) => {
     
        return data;
      })
      .catch((e) => {
        console.log("error---", e);
        window.location.href = "/no-access";
      });
      }
  };

  return (
    <>
      Loading...
    </>
  );
}




export const AppRoutes = () => {
  const [showElement, setShowElement] = React.useState(true);
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 1000);
  }, []);

  return (
    <Suspense fallback={<Loader status={showElement} />}>
      <Switch>
        <Route exact path="/validate-Login" component={CheckValidLogin} />
        <Route exact path="/" component={process.env.REACT_APP_LOGIN_TYPE == "SSO" ?  Login : Login} />
        <Route exact path="/login" component={process.env.REACT_APP_LOGIN_TYPE == "SSO" ?  NoAccess : Login} />
        <Route exact path="/forgot-password" component={process.env.REACT_APP_LOGIN_TYPE == "SSO" ?  NoAccess : ForgotPassword} />
        <Route exact path="/reset-password" component={process.env.REACT_APP_LOGIN_TYPE == "SSO" ?  NoAccess : ResetPassword} />
        <Route exact path="/set-password" component={ process.env.REACT_APP_LOGIN_TYPE == "SSO" ?  NoAccess : ResetPassword} />
        <PrivateRoute exact path="/profile" component={Profile}  accessRoles={[scopeProperty.RESOURCE_VIEW,scopeProperty.RESOURCE_CREATE,scopeProperty.RESOURCE_APPROVE,scopeProperty.RESOURCE_PUBLISH,scopeProperty.USER_CREATE,scopeProperty.ALL_ACCESS, scopeProperty.RESOURCE_PUBLISHED_VIEW]}/>
        <Route exact path="/endpoint" component={ResourcesApi} />
        <PrivateRoute
          path="/resource"
          component={Resource}
          accessRoles={[scopeProperty.RESOURCE_VIEW, scopeProperty.RESOURCE_CREATE,scopeProperty.RESOURCE_APPROVE,scopeProperty.RESOURCE_PUBLISH, scopeProperty.ALL_ACCESS, scopeProperty.RESOURCE_PUBLISHED_VIEW]}
        />
        <PrivateRoute
          exact
          path="/user-management"
          component={UserManagement}
          accessRoles={[scopeProperty.USER_CREATE]}
        />
        <PrivateRoute
          exact
          path="/trust"
          component={Trust}
          accessRoles={[scopeProperty.ALL_TRUST_ACCESS]}
        />
        <PrivateRoute
          exact
          path="/systemAdmin"
          component={Level1User}
          accessRoles={[scopeProperty.ALL_ACCESS]}
        />
        <PrivateRoute
          exact
          path="/roles"
          component={Roles}
          accessRoles={[scopeProperty.ALL_ACCESS]}
        />
        <PrivateRoute
          exact
          path="/notification-centre"
          component={Notification}
          accessRoles={[scopeProperty.RESOURCE_CREATE,scopeProperty.RESOURCE_APPROVE,scopeProperty.RESOURCE_PUBLISH,scopeProperty.USER_CREATE,scopeProperty.ALL_ACCESS, scopeProperty.RESOURCE_VIEW, scopeProperty.RESOURCE_PUBLISHED_VIEW]}
        />
        <PrivateRoute
          // exact
          path="/notification/view/:id"
          component={NotificationView}
          accessRoles={[scopeProperty.RESOURCE_CREATE,scopeProperty.RESOURCE_APPROVE,scopeProperty.RESOURCE_PUBLISH,scopeProperty.USER_CREATE,scopeProperty.ALL_ACCESS, scopeProperty.RESOURCE_VIEW, scopeProperty.RESOURCE_PUBLISHED_VIEW]}
        />
        <PrivateRoute
          exact
          path="/resource-library"
          component={ResourceLibrary}
          accessRoles={[scopeProperty.RESOURCE_CREATE, scopeProperty.RESOURCE_PUBLISH, scopeProperty.ALL_ACCESS, scopeProperty.RESOURCE_VIEW, scopeProperty.RESOURCE_PUBLISHED_VIEW, scopeProperty.RESOURCE_APPROVE]}
        />
        <PrivateRoute
          exact
          path="/resource-type"
          component={ResourceType}
          accessRoles={[scopeProperty.RESOURCE_CREATE]}
        />
        <PrivateRoute
          exact
          path="/resource-topic"
          component={ResourceTopic}
          accessRoles={[scopeProperty.RESOURCE_CREATE]}
        />
        <PrivateRoute
          // exact
          path="/resource-topics/view/:id"
          component={ResourceTopicView}
          accessRoles={[scopeProperty.RESOURCE_CREATE, scopeProperty.ALL_ACCESS]}
        />
        <PrivateRoute
          // exact
          path="/resource-types/view/:id"
          component={ResourceTypeView}
          accessRoles={[scopeProperty.RESOURCE_CREATE, scopeProperty.ALL_ACCESS]}
        />
        <Route exact path="/no-access" component={NoAccess} />
      </Switch>
    </Suspense>
  );
};
